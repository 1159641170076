import ResponsivePlot from "components/ResponsivePlot";
import React from "react";
import { generateNoisyLineData } from "utils/generate-data";

import styles from "./Crossover.module.less";

const layout = {
  title: "Crossover At 1 A/Cm2: # Start Stop Cycles",
  xaxis: {
    title: "# Start Stop Cycles Completed",
  },
  yaxis: {
    title: "Crossover [H2 in O2, %LFL]",
    range: [0, 15],
    ticksuffix: " %",
  },
};

const lflLayout = {
  title: "Change in %LFL Over Time",
  xaxis: {
    title: "Time",
  },
  yaxis: {
    title: "% LFL",
    range: [2, 6],
    ticksuffix: " %",
  },
};

export default function Crossover({ stacks }) {
  const crossoverData = stacks.map((s, idx) => {
    const { xValues, yValues } = generateNoisyLineData({
      randomStep: true,
      seed: `Stack-${s.id}`,
      maxX: 30,
      minY: 3 + 0.12 * idx,
      maxY: 8.4 + 0.12 * idx,
      numPoints: 8,
    });
    return {
      x: xValues,
      y: yValues,
      mode: "markers",
      type: "scatter",
      name: `S${idx + 1}`,
      marker: {
        size: 12,
      },
    };
  });

  const lflData = stacks.map((s, idx) => {
    const { xValues, yValues } = generateNoisyLineData({
      datetime: true,
      seed: `Stack-${s.id}`,
      minY: 3 + 0.12 * idx,
      maxY: 4 + 0.12 * idx,
      numPoints: 30,
    });
    return {
      x: xValues,
      y: yValues,
      type: "scatter",
      mode: "line",
      name: `S${idx + 1}`,
      line: {
        width: 1,
      },
    };
  });

  return (
    <div className={styles.container}>
      <div className={`${styles.section} ${styles.plotsContainer}`}>
        <div className={styles.plot}>
          <ResponsivePlot
            data={lflData}
            layout={lflLayout}
            config={{
              modeBarButtons: [["zoom2d", "pan2d", "zoomIn2d", "zoomOut2d", "autoScale2d"]],
              displaylogo: false,
              responsive: true,
            }}
          />
        </div>
        <div className={styles.plot}>
          <ResponsivePlot
            data={crossoverData}
            layout={layout}
            config={{
              modeBarButtons: [["zoom2d", "pan2d", "zoomIn2d", "zoomOut2d", "autoScale2d"]],
              displaylogo: false,
              responsive: true,
            }}
          />
        </div>
      </div>

      <div className={styles.section}>
        <div className={styles.card}>
          <div className={styles.title}>Global LFL</div>
          <div className={styles.content}>
            {stacks.map((s) => (
              <div key={s.id} className={styles.stackContainer}>
                <div className={styles.name}>{s.name}</div>
                <div className={styles.listItem}>
                  <div className={styles.val}>{`${s.lfl}%`} </div>
                  <div className={styles.unit}>LFL</div>
                </div>
                <div className={styles.listItem}>
                  <div className={styles.val}>{s.eol} </div>
                  <div className={styles.unit}>Hrs to Limit</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
