import { Tabs } from "antd";
import React from "react";

import styles from "./HistoricalCharts.module.less";
import StackVoltage from "./components/StackVoltage";
import StackTrend from "./components/StackTrend";

import stacks from "../../stackData";
import SubstackIndividual from "./components/SubstackIndividual";
import OtherTrends from "./components/OtherTrends";

// TODO: Get the proper substacks
const substacks = Array.from({ length: 30 }, (_, index) => ({
  id: index + 1,
}));

const tabItemsStacks = [
  {
    key: "1",
    label: "Stack Voltage Trend",
    children: <StackVoltage stacks={stacks} />,
  },
  {
    key: "2",
    label: "Individual Stack Trend",
    children: <StackTrend stacks={stacks} />,
  },
  {
    key: "3",
    label: "Other Trends",
    children: <OtherTrends stacks={stacks} />,
  },
];

const tabItemsSubstacks = [
  {
    key: "1",
    label: "Individual Substack Trend",
    children: <SubstackIndividual stacks={stacks} />,
  },
  {
    key: "2",
    label: "Voltage Trend",
    children: <StackVoltage stacks={stacks} substacks={substacks} />,
  },
];

export default function HistoricalCharts({ mode }) {
  return <Tabs items={mode === "STACK" ? tabItemsStacks : tabItemsSubstacks} className={styles.tabs} />;
}
