import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { Select, Segmented, DatePicker } from "antd";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styles from "./StackDashboard.module.less";
import GridView from "./components/GridView";
import WhiskerChart from "./components/WhiskerChart";
import SubstackGrid from "./SubstackGrid";
import Crossover from "./components/Crossover";
import IVCurve from "./components/IVCurve";
import DVDT from "./components/DVDT";
import stacks from "./stackData";
import HistoricalCharts from "./components/HistoricalCharts";

const { RangePicker } = DatePicker;
const defaultHistoricalRange = [dayjs().subtract(24, "hour"), dayjs()];

const historicalPresets = [
  {
    label: "Last 24 Hours",
    value: defaultHistoricalRange,
  },
  {
    label: "Last 7 Days",
    value: [dayjs().subtract(7, "day"), dayjs()],
  },
  {
    label: "Last 30 Days",
    value: [dayjs().subtract(30, "day"), dayjs()],
  },
];

const stackOtions = Array.from({ length: 16 }, (_, index) => ({
  label: `Stack ${index + 1}`,
  value: index + 1,
}));

const viewOptions = [
  {
    label: "Stack View",
    value: "STACK",
  },
  {
    label: "Substack View",
    title: "SUBSTACK_GROUP",
    options: stackOtions,
  },
  {
    label: "Crossover View",
    value: "CROSSOVER",
  },
  {
    label: "IV Curve",
    value: "IV_CURVE",
  },
  {
    label: "dV/dT View",
    value: "DV/DT",
  },
];

export default function StackDashboard({ mode }) {
  const entityText = mode === "SUBSTACK" ? "Substack" : "Stack";
  const { stackId } = useParams();
  const selectedView = stackId ? parseInt(stackId, 10) : mode;

  const [searchParams, setSearchParams] = useSearchParams();
  const [viewMode, setViewMode] = useState(searchParams.get("view") || "overview");
  const timeMode = searchParams.get("timeMode") || "realtime";
  const navigate = useNavigate();

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("view", viewMode);
    setSearchParams(newSearchParams);
  }, [viewMode, searchParams, setSearchParams]);

  const kpis = [
    { name: "Anode Inlet Flowrate", value: 4, unit: "L/min" },
    { name: "Anode Inlet Temp", value: 67, unit: "Deg C" },
    { name: "Anode Inlet Pressure", value: 4, unit: "barg" },
    { name: "H2 Flow Rate", value: 7, unit: "Kg/min" },
  ];

  const substackVoltages = [
    { min: 0.9, avg: 1.3, max: 1.8, runtime: 210 },
    { min: 1.1, avg: 1.4, max: 1.9, runtime: 185 },
    { min: 0.8, avg: 1.2, max: 1.7, runtime: 195 },
    { min: 1.0, avg: 1.35, max: 1.85, runtime: 172 },
    { min: 0.85, avg: 1.1, max: 1.5, runtime: 204 },
    { min: 1.2, avg: 1.45, max: 2.0, runtime: 160 },
    { min: 0.95, avg: 1.25, max: 1.75, runtime: 188 },
    { min: 1.15, avg: 1.38, max: 1.92, runtime: 176 },
    { min: 0.75, avg: 1.05, max: 1.4, runtime: 215 },
    { min: 1.3, avg: 1.5, max: 1.95, runtime: 142 },
    { min: 0.9, avg: 1.2, max: 1.6, runtime: 198 },
    { min: 1.05, avg: 1.32, max: 1.8, runtime: 165 },
    { min: 0.7, avg: 0.95, max: 1.3, runtime: 220 },
    { min: 1.25, avg: 1.45, max: 1.88, runtime: 152 },
    { min: 0.95, avg: 1.18, max: 1.65, runtime: 182 },
    { min: 1.1, avg: 1.36, max: 1.9, runtime: 170 },
    { min: 0.85, avg: 1.15, max: 1.55, runtime: 192 },
    { min: 1.2, avg: 1.42, max: 1.95, runtime: 158 },
    { min: 0.9, avg: 1.28, max: 1.75, runtime: 205 },
    { min: 1.05, avg: 1.3, max: 1.82, runtime: 174 },
    { min: 0.8, avg: 1.07, max: 1.45, runtime: 212 },
    { min: 1.15, avg: 1.4, max: 1.87, runtime: 166 },
    { min: 0.95, avg: 1.22, max: 1.7, runtime: 180 },
    { min: 1.3, avg: 1.48, max: 1.93, runtime: 147 },
    { min: 0.7, avg: 1.02, max: 1.38, runtime: 225 },
    { min: 1.1, avg: 1.35, max: 1.85, runtime: 162 },
    { min: 0.85, avg: 1.16, max: 1.6, runtime: 196 },
    { min: 1.2, avg: 1.44, max: 1.96, runtime: 154 },
    { min: 0.9, avg: 1.26, max: 1.78, runtime: 208 },
    { min: 1.05, avg: 1.33, max: 1.84, runtime: 168 },
  ];

  const renderContent = () => {
    if (selectedView === "STACK") {
      if (timeMode === "historical") {
        return <HistoricalCharts mode="STACK" />;
      }
      return viewMode === "overview" ? (
        <WhiskerChart mode="STACK" voltages={stacks.map((s) => s.voltageData)} />
      ) : (
        <GridView viewMode={viewMode} />
      );
    }
    if (selectedView === "CROSSOVER") {
      return <Crossover stacks={stacks} />;
    }
    if (selectedView === "IV_CURVE") {
      return <IVCurve />;
    }
    if (selectedView === "DV_DT") {
      return <DVDT stacks={stacks} />;
    }
    if (timeMode === "historical") {
      return <HistoricalCharts mode="SUBSTACK" />;
    }
    return viewMode === "overview" ? <WhiskerChart mode="SUBSTACK" voltages={substackVoltages} /> : <SubstackGrid />;
  };
  return (
    <div className={styles.container}>
      <div className={styles.toolbar}>
        <div className={styles.upperRow}>
          <div style={{ display: "flex", gap: 24 }}>
            <Select
              className={styles.select}
              options={viewOptions}
              placeholder="Select View"
              value={selectedView}
              onChange={(newView) => {
                if (newView === "CROSSOVER") {
                  navigate("/stack-dashboard/crossover");
                  return;
                }
                if (newView === "IV_CURVE") {
                  navigate("/stack-dashboard/iv-curve");
                  return;
                }
                if (newView === "DV/DT") {
                  navigate("/stack-dashboard/dv-dt");
                  return;
                }
                const newSearchParams = new URLSearchParams(searchParams);
                newSearchParams.set("view", viewMode);
                const basePath = `/stack-dashboard${Number.isInteger(newView) ? `/stacks/${newView}` : ""}`;
                navigate(`${basePath}?${newSearchParams.toString()}`);
              }}
            />
            {timeMode !== "historical" && (mode === "STACK" || mode === "SUBSTACK") && (
              <Segmented
                value={viewMode}
                options={[
                  { value: "overview", label: `${entityText} Overview` },
                  { value: "individual", label: `Individual ${entityText} View` },
                ]}
                onChange={(value) => {
                  setViewMode(value);
                }}
              />
            )}
          </div>
          <div style={{ display: "flex", gap: 12 }}>
            {timeMode === "historical" && (
              <div className={styles.rangePicker} style={{ width: 360 }}>
                <RangePicker showTime defaultValue={defaultHistoricalRange} presets={historicalPresets} />
              </div>
            )}
            <Segmented
              value={timeMode}
              options={[
                { value: "realtime", label: `Realtime` },
                { value: "historical", label: `Historical` },
              ]}
              onChange={(value) => {
                const newSearchParams = new URLSearchParams(searchParams);
                newSearchParams.set("timeMode", value);
                setSearchParams(newSearchParams);
              }}
            />
          </div>
        </div>
        {mode === "STACK" && timeMode === "realtime" && (
          <div className={styles.kpiRow}>
            {kpis.map((kpi) => (
              <div key={kpi.name} className={styles.kpiCard}>
                <div className={styles.kpiName}>{kpi.name}</div>
                <div className={styles.kpiVal}>{kpi.value}</div>
                <div className={styles.kpiUnit}>{kpi.unit}</div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className={styles.content}>{renderContent()}</div>
    </div>
  );
}
