import React from "react";
import { Modal } from "antd";
import ResponsivePlot from "components/ResponsivePlot";
import styles from "./PanelModal.module.less";

function PanelModal({
  open,
  setOpen,
  data,
  xAxisTitle = "",
  yAxisTitle = "",
  axisFontSize = 13,
  xAxisRange,
  yAxisRange,
  margin = { t: 5, r: 25, b: 35, l: 25 },
}) {
  const layout = {
    xaxis: {
      type: "date",
      title: {
        text: { xAxisTitle },
        font: {
          size: { axisFontSize },
        },
      },
      range: xAxisRange,
    },
    yaxis: {
      title: {
        text: yAxisTitle,
        font: {
          size: axisFontSize,
        },
      },
      range: yAxisRange,
    },
    legend: {
      orientation: "h",
      entrywidth: 0,
      y: 20,
      yanchor: "top",
      x: 0.5,
      xanchor: "center",
      font: {
        size: 11,
      },
      indentation: 14,
      itemwidth: 30,
    },
    margin,
  };

  return (
    <Modal
      title={data?.title}
      open={open}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      centered
      width="80vw"
      onCancel={() => setOpen(false)}
    >
      <div className={styles.panelContainer}>
        {data && (
          <ResponsivePlot data={data.data} layout={layout} config={{ displayModeBar: false, responsive: true }} />
        )}
      </div>
    </Modal>
  );
}

export default PanelModal;
