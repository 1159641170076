/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react";
import ResponsivePlot from "components/ResponsivePlot";
import PlotlyBase from "plotly.js-dist-min";
import createPlotlyComponent from "react-plotly.js/factory";

import styles from "./ChartCard.module.less";
import PanelModal from "../PanelModal";

const Plotly = createPlotlyComponent(PlotlyBase);

export default function ChartCard({
  title,
  data,
  xAxisTitle = "",
  yAxisTitle = "",
  axisFontSize = 13,
  xAxisRange,
  yAxisRange,
  margin = { t: 5, r: 25, b: 35, l: 30 },
  height,
  units = [""],
  responsive = true,
  onPanelClick = () => {},
  expandable = false,
}) {
  const [modalOpen, setModalOpen] = useState(false);

  const generateYAxes = () => {
    const axesConfig = {
      yaxis: {
        title: {
          text: yAxisTitle,
          font: {
            size: axisFontSize,
          },
        },
        range: yAxisRange,
        fixedrange: true,
        ticksuffix: ` ${units[0] || ""}`,
      },
    };

    units.forEach((unit, index) => {
      if (index > 0) {
        const axisKey = `yaxis${index + 1}`;
        axesConfig[axisKey] = {
          title: {
            text: unit,
            font: {
              size: axisFontSize,
            },
          },
          overlaying: "y",
          side: index % 2 === 0 ? "left" : "right",
          anchor: "free",
          autoshift: true,
          ticksuffix: ` ${unit}`,
        };
      }
    });

    return axesConfig;
  };

  const layout = {
    ...generateYAxes(),
    xaxis: {
      type: "date",
      title: {
        text: { xAxisTitle },
        font: {
          size: { axisFontSize },
        },
      },
      range: xAxisRange,
    },
    legend: {
      orientation: "h",
      entrywidth: 0,
      y: 20,
      yanchor: "top",
      x: 0.5,
      xanchor: "center",
      font: {
        size: 11,
      },
      indentation: 14,
      itemwidth: 30,
    },
    margin,
  };

  return (
    <div className={styles.card} style={{ height }} onClick={onPanelClick}>
      <div className={styles.title} onClick={() => setModalOpen(true)}>
        {title}
      </div>
      <div className={styles.plotContainer}>
        {responsive ? (
          <ResponsivePlot
            data={data}
            layout={layout}
            config={{
              modeBarButtons: [["zoom2d", "pan2d", "zoomIn2d", "zoomOut2d", "autoScale2d"]],
              displaylogo: false,
              responsive: true,
            }}
          />
        ) : (
          <div style={{ width: "100%", height: "100%" }}>
            <Plotly
              data={data}
              layout={{ ...layout }}
              config={{
                modeBarButtons: [["zoom2d", "pan2d", "zoomIn2d", "zoomOut2d", "autoScale2d"]],
                displaylogo: false,
                responsive: true,
              }}
              useResizeHandler
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        )}
      </div>
      {expandable && (
        <PanelModal
          open={modalOpen}
          setOpen={setModalOpen}
          data={{ data, title }}
          xAxisTitle={xAxisTitle}
          yAxisTitle={yAxisTitle}
          xAxisRange={xAxisRange}
          yAxisRange={yAxisRange}
        />
      )}
    </div>
  );
}
