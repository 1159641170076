import React from "react";
import ResponsivePlot from "components/ResponsivePlot";
import { generateBoxWhiskerData, generateRandomNumber } from "utils/generate-data";
import { useParams } from "react-router-dom";
import { ReactComponent as BoxWhiskerIcon } from "../../assets/box-whisker-icon.svg";

export default function WhiskerChart({ mode, voltages }) {
  const { stackId } = useParams();

  const generateStackNames = () => {
    const stacks = [];
    for (let i = 1; i <= 8; i++) {
      stacks.push(`${i}A<br>ID: ${generateRandomNumber(3, `${i}A`)}`);
      stacks.push(`${i}B<br>ID: ${generateRandomNumber(3, `${i}B`)}`);
    }
    return stacks;
  };

  const generateSubstackNames = () => {
    const stacks = [];
    for (let i = 1; i <= 30; i++) {
      stacks.push(i);
    }
    return stacks;
  };

  const generateNames = () => {
    switch (mode) {
      case "STACK":
        return generateStackNames();
      case "SUBSTACK":
        return generateSubstackNames();
      default:
        return generateStackNames();
    }
  };

  const xValues = generateNames();
  const averageVoltages = voltages.map((v) => v.avg);

  const tooltipText = voltages.map(
    (v, index) => `Stack #${index + 1}<br>Max: ${v.max} V<br>Avg: ${v.avg} V<br>Min: ${v.min} V`
  );
  const barTooltipText = voltages.map((n) => `Cumulative runtime: ${n.runtime} hours`);

  const barXValues = generateNames();
  const barYValues = voltages.map((v) => v.runtime);

  const maxRuntime = Math.max(voltages.map((v) => v.runtime));

  const seed = mode === "STACK" ? "stacks" : `substack-${stackId}`;

  const traces = averageVoltages.map((v, idx) => ({
    x: 0,
    y: generateBoxWhiskerData({ average: v, boxSpread: 0.3, whiskerExtension: 1.6, n: 20, seed }),
    type: "box",
    name: generateNames()[idx],
    marker: {
      color: "#008b8a",
      opacity: 1,
      size: 6,
      line: {
        color: "#008B8A",
        width: 2,
      },
    },
    boxpoints: false,
    yaxis: "y2",
    text: tooltipText,
  }));

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 28,
          fontSize: 12,
          marginTop: 14,
          zIndex: 5,
        }}
      >
        <div style={{ display: "flex", gap: 8 }}>
          <div style={{ width: 15, height: 15, background: "#C7DDCC", margin: "auto" }} />
          <div>Cumulative Runtime</div>
        </div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 3 }}>
            <BoxWhiskerIcon width={24} height={30} />
            <div style={{ display: "flex", flexDirection: "column", fontSize: 10, lineHeight: 1.3 }}>
              <div>max</div>
              <div>avg</div>
              <div>min</div>
            </div>
          </div>
          <div style={{ paddingLeft: 5 }}>Voltage</div>
        </div>
      </div>
      <div style={{ flex: 1, width: "100%", minHeight: 165, marginTop: -46 }}>
        <ResponsivePlot
          data={[
            ...traces,
            {
              x: barXValues,
              y: barYValues,
              type: "bar",
              xaxis: "x",
              yaxis: "y",
              width: 0.8,
              opacity: 1,
              marker: {
                color: "#C7DDCC",
              },
              name: "Cumulative Runtime",
              hovertemplate: barTooltipText.map((text) => `${text}<extra></extra>`),
            },
          ]}
          layout={{
            xaxis: {
              title: {
                text: mode === "STACK" ? "Stack" : "Substack #",
                standoff: 32,
              },
              tickvals: xValues,
              ticktext: xValues,
              fixedrange: true,
            },
            yaxis: {
              title: "Cumulative Runtime",
              side: "right",
              range: [1, maxRuntime * 1.2],
              fixedrange: true,
              titlefont: {
                size: 12,
              },
              showgrid: false,
              ticksuffix: " hrs",
            },
            yaxis2: {
              title: "Cell Voltage",
              fixedrange: true,
              range: [0, 2.2],
              overlaying: "y",
              side: "left",
              anchor: "free",
              titlefont: {
                size: 12,
              },
              ticksuffix: " V",
            },

            bargap: 0,
            barmode: "overlay",
            showlegend: false,
            margin: {
              l: 100,
              r: 100,
              b: 92,
              t: 80,
              pad: 20,
            },
          }}
          config={{
            modeBarButtons: [["zoom2d", "pan2d", "zoomIn2d", "zoomOut2d", "autoScale2d"]],
            displaylogo: false,
            responsive: true,
          }}
        />
      </div>
    </div>
  );
}
