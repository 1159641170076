const stacks = [
  {
    id: 237,
    name: "Stack 1A",
    dVdT: 1.78,
    eol: 2000,
    lfl: 3.2,
    voltageData: { min: 1, avg: 1.4, max: 1.9, runtime: 216 },
  },
  {
    id: 584,
    name: "Stack 2A",
    dVdT: 1.78,
    eol: 2000,
    lfl: 4.2,
    voltageData: { min: 0.9, avg: 1.3, max: 1.7, runtime: 180 },
  },
  {
    id: 912,
    name: "Stack 1B",
    dVdT: 1.78,
    eol: 2000,
    lfl: 4.9,
    voltageData: { min: 1, avg: 1.38, max: 2, runtime: 142 },
  },
  {
    id: 346,
    name: "Stack 2B",
    dVdT: 1.78,
    eol: 2000,
    lfl: 3.9,
    voltageData: { min: 1.15, avg: 1.15, max: 1.75, runtime: 164 },
  },
  {
    id: 671,
    name: "Stack 3A",
    dVdT: 1.78,
    eol: 2000,
    lfl: 4.1,
    voltageData: { min: 0.82, avg: 1.04, max: 1.2, runtime: 184 },
  },
  {
    id: 205,
    name: "Stack 3B",
    dVdT: 1.78,
    eol: 2000,
    lfl: 3.6,
    voltageData: { min: 1.6, avg: 1, max: 1.9, runtime: 154 },
  },
  {
    id: 893,
    name: "Stack 4A",
    dVdT: 1.78,
    eol: 2000,
    lfl: 5.2,
    voltageData: { min: 0.85, avg: 1.1, max: 1.3, runtime: 199 },
  },
  {
    id: 462,
    name: "Stack 4B",
    dVdT: 1.78,
    eol: 2000,
    lfl: 4.8,
    voltageData: { min: 1.2, avg: 1.4, max: 2, runtime: 200 },
  },
  {
    id: 718,
    name: "Stack 5A",
    dVdT: 1.78,
    eol: 2000,
    lfl: 5.0,
    voltageData: { min: 1.05, avg: 1.3, max: 1.9, runtime: 180 },
  },
  {
    id: 156,
    name: "Stack 5B",
    dVdT: 1.78,
    eol: 2000,
    lfl: 4.7,
    voltageData: { min: 1.1, avg: 1.38, max: 1.92, runtime: 179 },
  },
  {
    id: 539,
    name: "Stack 6A",
    dVdT: 1.78,
    eol: 2000,
    lfl: 3.5,
    voltageData: { min: 0.9, avg: 1.26, max: 2, runtime: 140 },
  },
  {
    id: 274,
    name: "Stack 6B",
    dVdT: 1.78,
    eol: 2000,
    lfl: 4.2,
    voltageData: { min: 0.8, avg: 1.46, max: 1.85, runtime: 189 },
  },
  {
    id: 801,
    name: "Stack 7A",
    dVdT: 1.78,
    eol: 2000,
    lfl: 5,
    voltageData: { min: 1.1, avg: 1.36, max: 1.85, runtime: 190 },
  },
  {
    id: 415,
    name: "Stack 7B",
    dVdT: 1.78,
    eol: 2000,
    lfl: 4.5,
    voltageData: { min: 1, avg: 1.36, max: 1.85, runtime: 170 },
  },
  {
    id: 692,
    name: "Stack 8A",
    dVdT: 1.78,
    eol: 2000,
    lfl: 4.8,
    voltageData: { min: 1.1, avg: 1.18, max: 1.75, runtime: 150 },
  },
  {
    id: 523,
    name: "Stack 8B",
    dVdT: 1.78,
    eol: 2000,
    lfl: 4.1,
    voltageData: { min: 0.75, avg: 1, max: 1.48, runtime: 190 },
  },
];

export default stacks;
